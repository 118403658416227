import style from "../styles/main.module.scss"
import {Link, useNavigate} from "react-router-dom";
import Chart from "react-apexcharts"
import {useContext, useEffect, useState} from "react";
import ApiContext from "../ApiContext";
const PieChart = ({data})=>{
    const options = {
        labels:["총 환급액","남은 적립금"],
        colors:['#0C4DA1', '#B3AEAD'],
        legend:{
            horizontalAlign:"center",
            formatter(seriesName,opts){
                console.log('opts',opts)
                return [seriesName,opts.w.config.series[opts.seriesIndex].toLocaleString()+"원"]
            }
        },
        tooltip:{
            y: {
                formatter: function(value) {
                    return value.toLocaleString() + '원';
                }
            }
        }
    }
    return <Chart options={options} series={data} id={"chart"} type={"donut"} width={600} height={280}/>
}
const Page = ()=>{
    const pb = useContext(ApiContext)
    const navigate = useNavigate()
    const [stat,setStat]=useState({
        withdraw:0,
        deposit:0,
        pageTotal:0,
        socialTotal:0
    })
    const [notices,setNotices]=useState([])
    useEffect(()=>{
        load()
    },[])
    const load = ()=>{
        pb.collection("user_stats").getOne(pb.authStore.model.id).then(record=>{
            setStat({
                withdraw: Number(record.withdraw),
                deposit:Number(record.deposit),
                pageTotal:Number(record.pageTotal),
                socialTotal:Number(record.socialTotal)
            })
        })
        pb.collection("notices").getList(1,5,{
            sort:"-created"
        }).then(res=>{
            setNotices(res.items)
        })
    }
    const pad = function (d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    return (
        <div className={style.dashboard+" full"}>
            <div>
                <div>
                    <Link to={"/purchase"}>
                        <h5>내음공간에서 구입한 물품과 용역을 등록하고<br/>
                            비용의 3%를 포인트로 적립하세요.</h5>
                        <span>구매내역 등록하기</span>
                    </Link>
                </div>
                <div>
                    <Link to={'/social'}>
                        <h5>상생기반 플랫폼에 적립된<br/>
                            사회적가치를 실현하세요.</h5>
                        <span>사회적 가치 실현하기</span>
                    </Link>
                </div>
            </div>
            <h3 style={{marginTop:30}}>상생기반 플랫폼 내역</h3>
            <div className={style.cards}>
                <div style={{justifyContent:"flex-start",gap:"30px"}}>
                    <h5>내음공간 총 구매내역</h5>
                    <b>{stat.pageTotal.toLocaleString()}건</b>
                </div>
                <div style={{justifyContent:"flex-start",gap:"30px"}}>
                    <h5>적립 포인트</h5>
                    <b>{stat.deposit.toLocaleString()}p</b>
                </div>
                <div style={{justifyContent:"flex-start",gap:"30px"}}>
                    <h5>환급받은 적립포인트</h5>
                    <b>{stat.withdraw.toLocaleString()}원</b>
                </div>
            </div>
            <div>
                <div>
                    <div>
                        <h5>공지사항</h5>
                        <Link to={"/notice"}>
                            전체보기
                        </Link>
                    </div>
                    <ul>
                        {
                            notices.map((record,k)=>{
                                return (
                                    <li key={record.id} onClick={()=>navigate("notice?id="+record.id)}>
                                        <span>{pad(k + 1)}</span>
                                        <p>{record.title}</p>
                                        <small>{record.created.substring(0,10)}</small>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div>
                    <div>
                        <h5>통계</h5>
                        <Link to={"/social"}>
                            후원금 청구하기
                        </Link>
                    </div>
                    <PieChart data={[stat.withdraw,stat.deposit - stat.withdraw]} />
                </div>
            </div>
        </div>
    )
}
export default Page
