import AuthLayout from "../AuthLayout";
import Logo from "../assets/logo.png"
import {Link, useNavigate} from "react-router-dom";
import style from "../styles/auth.module.scss"
import {useContext, useState} from "react";
import ApiContext from "../ApiContext";
const Login = ()=>{
    const pb = useContext(ApiContext)
    const navigate = useNavigate()
    const [form,setForm]=useState({
        email:"",
        password:""
    })
    const [error,setError]=useState(false)
    const login = ()=>{
        pb.collection("users").authWithPassword(form.email,form.password).then(()=>{
            navigate("/")
        }).catch(()=>{
            alert("입력한 정보가 맞지 않습니다.")
            setError(true)
        })
    }
    return (
        <AuthLayout>
            <img src={Logo} alt=""/>
            <h1>
                상생기반 플랫폼에서<br/>
                구입비용의 3%를 적립하고 환급받으세요.
            </h1>
            <div className={style.tab}>
                <Link className={"active"} to={"/login"}>로그인</Link>
                <Link to={"/join"}>회원가입</Link>
            </div>
            <label htmlFor="id">ID</label>
            <input className={error?"error":""} placeholder={"회원가입시 사용한 이메일 주소를 입력하세요."} type="text" name={"id"} onChange={e=>setForm({...form,email:e.target.value})}/>
            <label htmlFor="pw">비밀번호</label>
            <input className={error?"error":""} placeholder={"비밀번호를 입력하세요."} type="password" name={"pw"} onChange={e=>setForm({...form,password:e.target.value})} onKeyUp={e=>e.key==="Enter"?login():null}/>
            <button onClick={login} className={"submit"}>로그인하기</button>
            <Link className={"info"} to={"/find"}>*아이디 또는 비밀번호를 모릅니다.</Link>
        </AuthLayout>
    )
}
export default Login
