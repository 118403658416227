import AuthLayout from "../AuthLayout";
import {Link, useNavigate} from "react-router-dom";
import style from "../styles/auth.module.scss";
import {useContext, useState} from "react";
import ApiContext from "../ApiContext";
import {NumericFormat} from "react-number-format";
const FindID = ()=>{
    const pb = useContext(ApiContext)
    const [form,setForm]=useState({})
    const [find,setFind]=useState(null)
    const navigate = useNavigate()
    const updateForm = (key,value)=>{
        let obj={}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    const search = ()=>{
        pb.collection("users").getList(1,1,{
            filter:`(charge="${form.charge}" && phone="${form.phone}")`,
            find:form.charge
        }).then(res=>{
            if(res.items.length){
                setFind(res.items[0])
            }else{
                alert("입력한 정보의 아이디가 조회되지 않습니다.")
            }
        })
    }
    return (
        <AuthLayout>
            <Link className={"back"} to={"/login"}>뒤로가기</Link>
            <h1>
                아이디 및 비밀번호 찾기
            </h1>
            <div className={style.tab}>
                <Link className={"active"} to={"/find"}>ID</Link>
                <Link to={"/find-pw"}>비밀번호</Link>
            </div>
            {
                find?<>
                    <p className={"find"}>
                        회원님의 아이디는 <b>{find.email}</b>입니다.
                    </p>
                        <button className={"submit"} onClick={()=>navigate("/login")}>로그인하러 가기</button>
                    </>
                    :<>
                        <label htmlFor="company_no">담당자명</label>
                        <input placeholder={"회원가입시 등록한 담당자명을 입력하세요."} type="text" name={"charge"} onChange={e=>updateForm("charge",e.target.value)}/>
                        <label htmlFor="phone">전화번호</label>
                        <NumericFormat placeholder={"회원가입시 사용한 전화번호를 입력하세요.(-없이 입력해주세요.)"} allowLeadingZeros allowNegative={false} onChange={e=>updateForm("phone",e.target.value)} />
                        <button className={"submit"} onClick={search}>ID찾기</button>
                    </>
            }
        </AuthLayout>
    )
}
export default FindID
