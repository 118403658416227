import Table from "../parts/Table";
import style from "../styles/main.module.scss";
import img from "../assets/notice-back.png"
import ApiContext from "../ApiContext";
import {useContext, useEffect, useMemo, useState} from "react";
import Modal from "../parts/Modal";
import {useLocation} from "react-router-dom";
const Page = ()=>{
    const pb = useContext(ApiContext)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id")
    const [detail,select]=useState(null)
    const [info,setInfo]=useState({
        items:[],
        page:1,
        perPage:8,
        totalItems:0,
        totalPages:1
    })
    useEffect(()=>{
        load()
    },[])
    useEffect(()=>{
        if(id){
            let target = info.items.find(v=>v.id===id)
            select(target)
        }
    },[info])
    const Comment = ({detail})=>{
        const [msg,setMsg]=useState("")
        const createComment = ()=>{
            pb.collection("comments").create({
                content:msg,
                notice:detail.id,
                name:pb.authStore.model.charge
            }).then(()=>{
                load()
            })
        }
        let comments = []
        if(detail){
            if(detail.expand){
                if(detail.expand["comments(notice)"]){
                    comments = detail.expand["comments(notice)"]
                }
            }
        }
        return (
            <div>
                {
                    comments.map(comment=>{
                        return (
                            <div key={comment.id}>
                                            <span>
                                                {comment.name}
                                            </span>
                                <p dangerouslySetInnerHTML={{__html:comment.content.replace(/\n/g,"<br/>")}}>

                                </p>
                                <small>
                                    {comment.created.substring(0,10)}
                                </small>
                            </div>
                        )
                    })
                }
                <div>
                    <textarea cols="30" rows="2"  onChange={e=>setMsg(e.target.value)}>

                    </textarea>
                    <button type="button" onClick={createComment}>글쓰기</button>
                </div>
            </div>
        )
    }
    const load = (page = 1)=>{
        return pb.collection("notices").getList(page,8,{
            expand:"comments(notice)",
            sort:"-created"
        }).then(res=>{
            setInfo(res)
            if(detail){
                select(res.items.find(v=>v.id===detail.id))
            }
        })
    }
    const pad = function (d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    const parser = (record,k)=>{
        return [
            pad(k+1),
            record.title,
            record.created.substring(0,10)
        ]
    }
    return (
        <div className={"withImg"}>
            <div style={{backgroundImage:"url("+img+")"}}>
                <div></div>
            </div>
            <div>
                <div className={style.title}>
                    <h1>공지사항</h1>
                </div>
                <Table columns={["순번","제목","게시일자"]}
                       records={info.items}
                       handler={parser}
                       widths={[30,null,90]}
                       classes={["no","main","date"]}
                       info={info}
                       onPage={page=>load(page)}
                       onClick={record=>select(record)}
                />
                {
                    detail?
                        <Modal onClose={()=>select(null)}>
                            <div className={style.notice}>
                                <h5>{detail.title}</h5>
                                <div>
                                    <small>{detail.created.substring(0,10)}</small>
                                    {
                                        detail.file?
                                            <a href={pb.getFileUrl(detail,detail.file)}>첨부파일 확인</a>
                                            :null
                                    }
                                </div>
                                <div>
                                    <div dangerouslySetInnerHTML={{__html:detail.contents}}>

                                    </div>
                                    <Comment detail={detail} />
                                </div>
                            </div>
                        </Modal>
                        :null
                }
            </div>
        </div>
    )
}
export default Page
