import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import Layout from "./Layout";
import Login from "./pages/Login";
import Join from "./pages/Join";
import FindID from "./pages/FindID";
import Dashboard from "./pages/Dashboard";
import Purchase from "./pages/Purchase";
import Point from "./pages/Point";
import Notice from "./pages/Notice";
import {useContext} from "react";
import ApiContext from "./ApiContext";
import FindPW from "./pages/FindPW";
import Reset from "./pages/Reset";

const makeRotues = (pb)=>{
    const routes = [
        {
            path:"/",
            element:<Layout />,
            loader(){
                if(pb.authStore.isValid){
                    return null
                }else{
                    return redirect("login")
                }

            },
            children:[
                {
                    index:true,
                    element:<Dashboard />
                },
                {
                    path:"purchase",
                    element:<Purchase />
                },
                {
                    path:"social",
                    element:<Point />
                },
                {
                    path:"notice",
                    element:<Notice />
                }
            ]
        },
        {
            path:"login",
            element:<Login />,
            loader(){
                if(pb.authStore.isValid){
                    return redirect("/")
                }else{
                    return null
                }

            },
        },
        {
            path:"join",
            element:<Join />
        },
        {
            path:"find",
            element:<FindID />
        },
        {
            path:"find-pw",
            element:<FindPW />
        },
        {
            path:"reset/:token",
            element:<Reset />
        }
    ]
    return createBrowserRouter(routes)
}

const Provider = ()=>{
    const pb = useContext(ApiContext)
    return (
        <RouterProvider router={makeRotues(pb)} />
    )

}
export default Provider
