import {Link, NavLink, Outlet, useNavigate} from "react-router-dom";
import style from "./styles/main.module.scss"
import {useContext, useEffect, useState} from "react";
import ApiContext from "./ApiContext";
import {logo,logout as logoutImg,logoutD,purchase,purchaseD,social,socialD,dashboard,dashboardD,notice,noticeD} from "./assets"

const menus = [
    {
        path:"/",
        name:"대시보드",
        active:dashboard,
        deActive:dashboardD
    },
    {
        path:"/purchase",
        name:"구매목록",
        active:purchase,
        deActive:purchaseD
    },
    {
        path:"/social",
        name:"사회적가치",
        active:social,
        deActive:socialD
    },
    {
        path:"/notice",
        name:"공지사항",
        active:notice,
        deActive:noticeD
    },
]
const MenuNav = ({item})=>{
    const [isHover,setIsHover]=useState(false)
    return (
        <NavLink to={item.path} onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}>
            {
                ({isActive})=>{
                    const on = isActive || isHover
                    return (
                        <>
                            <img src={on?item.active:item.deActive} alt={item.name}/>
                            <span className={on?"active":""}>{item.name}</span>
                        </>
                    )
                }
            }
        </NavLink>
    )
}

const Layout = ({children})=>{
    const pb = useContext(ApiContext)
    const navigate = useNavigate()
    useEffect(()=>{

        pb.collection("users").authRefresh()
    },[])
    const logout = ()=>{
        pb.authStore.clear()
        navigate("/login")
    }
    return (
        <main className={style.main}>
            <aside className={style.menu}>
                <nav>
                    <Link to={"/"}>
                        <img className={"logo"} src={logo} alt=""/>
                    </Link>
                    <ul>
                        {
                            menus.map(item=>{
                                return (
                                    <li key={item.path}>
                                        <MenuNav item={item} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <a href="#" onClick={logout}>
                        <img src={logoutD} alt=""/>
                        <span>로그아웃</span>
                    </a>
                </nav>
            </aside>
            <section>
                <Outlet />
                <footer className={style.footer}>
                    <p>(우 14995) 경기도 시흥시 승지로60번길 12(광장프라자 501호)    |   전화:031-520-4137    |    팩스:070-4325-0608</p>
                    <div>
                        <a href="#" >
                            <img src="/logo/site.png" alt="내음공간"/>
                        </a>
                        <a href="http://www.global1122.com/" target={"_blank"}>
                            <img src="/logo/gift.png" alt="내음기프트"/>
                        </a>
                        <a href="http://www.naeyeomflower.com/" target={"_blank"}>
                            <img src="/logo/flower.png" alt="기억해꽃내음"/>
                        </a>
                    </div>
                </footer>
            </section>
        </main>
    )
}
export default Layout
