import moduleStyle from '../styles/part.module.scss'
import {forwardRef, useImperativeHandle, useRef} from "react";
const Upload = ({name,onUpload,style,type = []},ref)=>{
    const uploadRef = useRef()
    useImperativeHandle(ref,()=>{
        return {
            click:()=>{
                click()
            }
        }
    })
    const click = ()=>{
        uploadRef.current.click()
    }
    return (
        <div style={style} className={moduleStyle.upload}>
            <button onClick={click}>{name}</button>
            <input ref={uploadRef} type="file" onChange={e=> {
                if(e.target.files.length){
                    let newFile = e.target.files[0]
                    if(newFile.size>5242880){
                        alert("최대용량(5Mb)를 초과합니다.")
                        e.target.value=""
                        return
                    }
                    if(type.length){
                        let typeChecked = type.some(t=>newFile.type.indexOf(t)!==-1)
                        if(!typeChecked){
                            alert("파일 타입을 확인해주세요.")
                            e.target.value=""
                            return
                        }
                    }
                    onUpload(newFile)
                }
            }}/>
        </div>
    )
}
const HOC = forwardRef(Upload)
export default HOC
