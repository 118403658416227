import {useNavigate, useParams} from "react-router-dom";
import {useContext, useState} from "react";
import AuthLayout from "../AuthLayout";
import ApiContext from "../ApiContext";

const Page= ()=>{
    const pb = useContext(ApiContext)
    const query = useParams()
    const [form,setForm]=useState({
        token:query.token
    })
    const navigate = useNavigate()
    const reset = ()=>{
        pb.collection("users").confirmPasswordReset(form.token,form.password,form.passwordConfirm).then(()=>{
            alert("변경완료. 로그인해주세요.")
            navigate("/login")
        }).catch(()=>{
            alert("다시 시도해주세요.")
            navigate("/find-pw")
        })
    }
    const updateForm=(key,value)=>{
        let obj = {}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    return (
        <AuthLayout>
            <h1>
                비밀번호 재설정
            </h1>

            <label htmlFor="company_no">새로운 비밀번호</label>
            <input placeholder={"새로운 비밀번호"} type="password" name={"password"} onChange={e=>updateForm("password",e.target.value)}/>
            <label htmlFor="email">비밀번호확인</label>
            <input placeholder={"비밀번호확인"} type="password" name={"passwordConfirm"} onChange={e=>updateForm("passwordConfirm",e.target.value)}/>
            <button className={"submit"} onClick={reset}>비밀번호 재설정</button>
        </AuthLayout>
    )
}
export default Page
