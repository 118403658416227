import style from "../styles/part.module.scss"
const Pagination = ({info,onPage})=>{
    const prev = ()=>{
        if(info.page>1){
            onPage(info.page-1)
        }
    }
    const next = ()=>{
        if(info.page<info.totalPages){
            onPage(info.page+1)
        }
    }
    return (
        <nav>
            <a href="#" onClick={prev}>이전</a>
            <ul>
                {
                    new Array(5).fill(0).map((zero,v)=>{
                        if(v+info.page-2>0 && v+info.page-2<=info.totalPages){
                            return (
                                <li key={v}>
                                    <a className={v===2?"active":null} href="#" onClick={()=>onPage(v+info.page-2)}>{v+info.page-2}</a>
                                </li>
                            )
                        }else{
                            return null
                        }
                    })
                }
            </ul>
            <a href="#" onClick={next}>다음</a>
        </nav>
    )
}
const Table = ({thead,tfoot,columns,handler,records,sortable,onSort,classes,widths,info,onPage,onClick})=>{
    return (
        <table className={style.table}>
            <thead>
                <tr>
                    <td colSpan={columns.length}>
                        {thead}
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    {
                        columns.map((column,k)=>{
                            return (
                                <th key={column} className={widths?(widths[k]===null?"main":null):null}>
                                    {column}
                                </th>
                            )
                        })
                    }
                </tr>
                {
                    records.map((record,k)=>{return {id:record.id,data:handler(record,k),record:record}}).map(list=>{
                        return (
                            <tr key={list.id} onClick={()=>onClick(list.record)}>
                                {
                                    list.data.map((item,k)=>{
                                        return (
                                            <td key={list.id+item+k} className={classes?classes[k]:null} width={widths?widths[k]:null}>
                                                {item}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={columns.length}>
                    {
                        tfoot?
                            <div className={"tfoot"}>
                                <div>

                                </div>
                                <Pagination info={info} onPage={onPage} />
                                <div>
                                    {tfoot}
                                </div>
                            </div>
                            :
                            <Pagination info={info} onPage={onPage} />
                    }
                </td>
            </tr>
            </tfoot>
        </table>
    )
}
export default Table
