import Table from "../parts/Table";
import style from "../styles/main.module.scss";
import {useContext, useEffect, useState} from "react";
import SearchInput from "../parts/SearchInput";
import SearchSelect from "../parts/SearchSelect";
import ApiContext from "../ApiContext";
import Modal from "../parts/Modal";
import Upload from "../parts/Upload";
import {NumericFormat} from "react-number-format";

const Page = ()=>{
    const pb = useContext(ApiContext)
    const defaultForm = {
        user:pb.authStore.model.id,
        status:"신청완료",
        dept:pb.authStore.model.dept,
        charge:pb.authStore.model.charge
    }
    const [create,setCreate]=useState(false)
    const [keyword,setKeyword]=useState("")
    const [filter,setFilter]=useState([])
    const [detail,select]=useState(null)
    const [total,setTotal]=useState({
        count:0,
        sum:0,
        available:0,
    })
    const [form,setForm]=useState(defaultForm)
    const [info,setInfo]=useState({
        items:[],
        page:1,
        perPage:8,
        totalItems:0,
        totalPages:1
    })
    useEffect(()=>{
        load()
    },[])
    useEffect(()=>{
        load()
    },[filter])
    const load = (page=1)=>{
        let filterString = `user="${pb.authStore.model.id}"`
        if(filter.length){
            let statusFilter = filter.map(item=>{
                return `status = "${item}"`
            })
            filterString+=`&& (${statusFilter.join("||")})`
        }
        if(keyword){
            let searchColumn = ["dept","charge"]
            let searchFilter = searchColumn.map(item=>{
                return `${item} ~ "%${keyword}%"`
            })
            filterString+=`&& (${searchFilter.join("||")})`
        }
        pb.collection("socials").getList(page,8,{
            sort:"-created",
            filter:filterString
        }).then(res=>{
            setInfo(res)
        })
        pb.collection("user_stats").getOne(pb.authStore.model.id).then(res=>{
            setTotal({
                count: Number(res.deposit),
                sum:Number(res.withdraw),
                available: Number(res.available)
            })
        })
    }
    const register = ()=>{
        let filled = form.dept && form.charge && form.point && form.receipt
        let over = Number(form.point)>(total.count-total.available)
        if(!filled){
            alert("항목을 모두 채워주세요.")
            return
        }
        if(over){
            alert("신청가능 금액을 벗어납니다.")
            return
        }
        let multiPartForm = new FormData()
        Object.keys(form).forEach(key=>{
            multiPartForm.append(key,form[key])
        })
        return pb.collection("socials").create(multiPartForm).then(()=>{
            alert("환급신청이 완료되었습니다.")
            setCreate(false)
            load()
            setForm(defaultForm)
        })
    }
    const updateForm=(key,value)=>{
        console.log(key,value)
        let obj = {}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    const pad = function (d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    const Status = ({status})=>{
        const colors = {
            "신청완료":"#0C4DA1",
            "담당자확인":"#666666",
            "적립완료":"#34B44A",
            "신청반려":"#F5891D",
            "입금완료":"#34B44A",
            "취소":"#F5891D"
        }
        return (
            <span style={{color:colors[status]}}>{status}</span>
        )
    }
    const parser = (record,k)=>{
        return [
            pad(k+1),
            record.dept,
            record.charge,
            record.created.substring(0,10),
            Number(record.point).toLocaleString()+"원",
            <Status status={record.status} />,
            ""
        ]
    }
    const download = (name)=>{
        if(name){
            window.open(pb.getFileUrl(detail,name),"_blank","download")
        }
    }
    const resubmit = ()=>{
        let {point}=detail
        setForm({
            ...defaultForm,
            point
        })
        setCreate(true)
        select(null)
    }
    return (
        <div className={"full"}>
            <div className={style.title}>
                <h1>포인트 현황 및 환급내역</h1>
                <p>구매내역을 등록하여 적립받은 포인트를 확인하세요. 적립된 포인트는 환급 신청하기를 통해 환급받을 수 있습니다.</p>
            </div>
            <div className={style.cards}>
                <div>
                    <h5>상생기반 플랫폼 총 적립금</h5>
                    <b>{total.count.toLocaleString()}원</b>
                </div>
                <div>
                    <h5>환급받은 총 적립금</h5>
                    <b>{total.sum.toLocaleString()}원</b>
                </div>
                <button type={"button"} onClick={()=>setCreate(true)}>
                    환급 신청하기
                </button>
            </div>
            <Table columns={["순번","담당부서명","담당(구매자)","신청일자","신청금액","현황",""]}
                   widths={[110,180,180,170,170,170,null]}
                   classes={["no","","","date","main number","status"]}
                   records={info.items}
                   handler={parser}
                   thead={
                        <div className={style.thead}>
                            <SearchInput onChange={v=>setKeyword(v)} onFocusOut={load} />
                            <SearchSelect items={["신청완료","담당자확인","입금완료","취소"]} value={filter} onChange={v=>setFilter(v)} />
                        </div>
                   }
                   info={info}료
                   onPage={page=>load(page)}
                   onClick={record=>select(record)}
            />
            {
                create?
                    <Modal onClose={()=>setCreate(false)} onRegister={register}>
                        <h2>
                            사회적가치 청구하기
                        </h2>
                        <p>
                            * 후원금영수증을 발행하기 전 내음공간(지점)/내음사회적협동조합(법인)의 사업자를 확인해주세요.<br/>
                            * 후원금 영수증 발행일은 입금일 입니다.
                        </p>
                        <ul>
                            <li>
                                <b>
                                    담당부서명
                                </b>
                                <input type="text" value={pb.authStore.model.dept}  readOnly={true}/>
                            </li>
                            <li>
                                <b>
                                    담당자
                                </b>
                                <input type="text" value={pb.authStore.model.charge} readOnly={true}/>
                            </li>
                            <li>
                                <b>
                                    신청금액
                                </b>
                                <NumericFormat value={form.point} onChange={e=>updateForm("point",Number(e.target.value.replace(/,/g,"")))} allowLeadingZeros thousandSeparator={","}  />
                            </li>
                            <li>
                                <b>
                                    신청가능금액
                                </b>
                                <span>{(total.count-total.available).toLocaleString()}</span>
                            </li>
                            <li>
                                <b>
                                    후원금영수증
                                </b>

                                {
                                    form.receipt?
                                        <span>{form.receipt.name}</span>
                                        :
                                        <p>*jpg, pdf, png 파일 첨부 가능</p>
                                }
                                <Upload onUpload={file=>updateForm("receipt",file)}  name={"파일 찾기"} type={["png","jpeg","jpg","pdf"]} />
                            </li>
                            <li>
                                <b>
                                    기타파일첨부
                                </b>

                                {
                                    form.attach?
                                        <span>{form.attach.name}</span>
                                        :
                                        <p>*jpg, pdf, png 파일 첨부 가능</p>
                                }
                                <Upload onUpload={file=>updateForm("attach",file)}  name={"파일 찾기"} type={["png","jpeg","jpg","pdf"]} />
                            </li>
                        </ul>
                    </Modal>
                    :null
            }
            {
                detail?
                    <Modal onClose={()=>select(null)} onUpdate={detail.status==="취소"?resubmit:null}>
                        <h2>
                            후원금 청구내역
                        </h2>
                        <p>
                            * 후원금영수증을 발행하기 전 내음공간(지점)/내음사회적협동조합(법인)의 사업자를 확인해주세요.<br/>
                            * 후원금 영수증 발행일은 입금일 입니다.
                        </p>
                        <ul>
                            <li>
                                <b>
                                    담당부서명
                                </b>
                                <span>{detail.dept}</span>
                            </li>
                            <li>
                                <b>
                                    담당자
                                </b>
                                <span>{detail.charge}</span>
                            </li>
                            <li>
                                <b>
                                    신청금액
                                </b>
                                <span>{Number(detail.point).toLocaleString()}</span>
                            </li>
                            <li>
                                <b>
                                    후원금영수증
                                </b>

                                {
                                    <span>{detail.receipt}</span>
                                }
                                <button onClick={()=>download(detail.receipt)}>다운로드</button>
                            </li>
                            <li>
                                <b>
                                    기타파일첨부
                                </b>

                                {
                                    <span>{detail.attach}</span>
                                }
                                <button onClick={()=>download(detail.attach)}>다운로드</button>
                            </li>
                            {
                                detail.status==="취소"?
                                    <li>
                                        <b style={{color:"red"}}>
                                            취소 사유
                                        </b>
                                        <span>
                                            {detail.memo}
                                        </span>
                                    </li>
                                    :null
                            }
                        </ul>
                    </Modal>:null
            }
        </div>
    )
}
export default Page
