import AuthLayout from "../AuthLayout";
import {Link, useNavigate} from "react-router-dom";
import style from "../styles/auth.module.scss";
import {useContext, useState} from "react";
import ApiContext from "../ApiContext";
const FindID = ()=>{
    const pb = useContext(ApiContext)
    const [form,setForm]=useState({})
    const [find,setFind]=useState(null)
    const navigate = useNavigate()
    const updateForm = (key,value)=>{
        let obj={}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    const search = ()=>{
        pb.collection("users").getList(1,1,{
            filter:`(charge="${form.charge}" && email="${form.email}")`,
            find:form.charge
        }).then(res=>{
            if(res.items.length){
                setFind(res.items[0])
                pb.collection("users").requestPasswordReset(form.email)
            }else{
                alert("입력한 정보가 조회되지 않습니다.")
            }
        })
    }
    const update = ()=>{
        alert("준비중입니다.")
        return
        pb.collection("users").update(find.id,form,{
            find:form.company_no
        }).then(()=>{
            navigate("/login")
        })
    }
    return (
        <AuthLayout>
            <Link className={"back"} to={"/login"}>뒤로가기</Link>
            <h1>
                아이디 및 비밀번호 찾기
            </h1>
            <div className={style.tab}>
                <Link to={"/find"}>ID</Link>
                <Link className={"active"} to={"/find-pw"}>비밀번호</Link>
            </div>
            {
                find?<>
                        <p className={"find"}>이메일을 확인하고 비밀번호를 변경해주세요.</p>
                        <button className={"submit"} onClick={()=>navigate("/login")}>로그인하러 가기</button>
                    </>
                    :<>
                        <label htmlFor="company_no">담당자명</label>
                        <input placeholder={"회원가입시 등록한 담당자명을 입력하세요."} type="text" name={"charge"} onChange={e=>updateForm("charge",e.target.value)}/>
                        <label htmlFor="email">이메일주소</label>
                        <input placeholder={"회원가입시 사용한 이메일주소를 입력하세요."} type="text" name={"email"} onChange={e=>updateForm("email",e.target.value)}/>
                        <button className={"submit"} onClick={search}>비밀번호 찾기</button>
                    </>
            }
        </AuthLayout>
    )
}
export default FindID
