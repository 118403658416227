import AuthLayout from "../AuthLayout";
import Logo from "../assets/logo.png";
import style from "../styles/auth.module.scss";
import {Form, Link, useNavigate} from "react-router-dom";
import Upload from "../parts/Upload";
import {useContext, useRef, useState} from "react";
import ApiContext from "../ApiContext";
import {NumericFormat} from "react-number-format";
const Join = ()=>{
    const pb = useContext(ApiContext)
    const [form,setForm]=useState({
        "emailVisibility": true
    })
    const businessUploadRef = useRef()
    const accountUploadRef = useRef()
    const navigate = useNavigate()
    const [loading,setLoading]=useState(false)
    const updateForm = (key,value)=>{
        let obj={}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    const join = ()=>{
        let filled = form.email
            && form.password
            && form.passwordConfirm
            && form.company
            && form.dept
            && form.phone
            && form.business
            && form.account
        if(!filled){
            console.log(form,filled)
            alert("모든 항목을 채워주세요.")
            return
        }

        setLoading(true)
        let multiPartForm = new FormData()
        Object.keys(form).forEach(key=>{
            multiPartForm.append(key,form[key])
        })
        return pb.collection("users").create(multiPartForm).then(()=>{
            alert("가입완료! 입력한 정보로 로그인해주세요.")
            navigate("/login")
        }).catch((err)=>{
            Object.keys(err.data.data).forEach(key=>{
                console.log(key)
                if(key==="passwordConfirm"){
                    alert("패스워드가 일치하지 않습니다.")
                    return
                }else if(key==="email"){
                    alert("형식이 알맞지 않거나 이미 있는 이메일입니다.")
                    return
                }else if(key==="phone"){
                    alert("연락처는 최소 8자 이상입력해야합니다.")
                    return
                }
            })
        }).finally(()=>{

            setLoading(false)
        })
    }

    const duplicateCheck = ()=>{
        if(form.email){
            pb.collection("users").getFullList({
                email:form.email
            }).then(res=>{
                console.log(res)
                if(res.length){
                    alert("이미 사용중입니다.")
                    updateForm("email","")
                }
            })
        }
    }
    return (
        <AuthLayout>
            <Link className={"back"} to={"/login"}>뒤로가기</Link>
            <h1>
                회원가입
            </h1>
            <div className={style.tab}>
                <Link to={"/login"}>로그인</Link>
                <Link className={"active"} to={"/join"}>회원가입</Link>
            </div>
            <label htmlFor="id">ID</label>
            <input placeholder={"회원가입시 사용할 이메일 주소를 입력하세요."} type="text" name={"id"} value={form.email} onChange={e=>updateForm("email",e.target.value)} onBlur={duplicateCheck}/>
            <label htmlFor="pw">비밀번호</label>
            <input placeholder={"비밀번호를 입력하세요."} type="password" name={"pw"} onChange={e=>updateForm("password",e.target.value)}/>
            <label htmlFor="confirm">비밀번호확인</label>
            <input placeholder={"비밀번호를 한번 더 입력하세요."} type="password" name={"confirm"} onChange={e=>updateForm("passwordConfirm",e.target.value)}/>
            <div style={{display:"flex",columnGap:10}}>
                <div style={{flex:1}}>
                    <label htmlFor="company">기관이름</label>
                    <input placeholder={"기관이름을 입력하세요."} type="text" name={"company"} onChange={e=>updateForm("company",e.target.value)}/>
                </div>
                <div style={{flex:1}}>
                    <label htmlFor="dept">부서</label>
                    <input placeholder={"부서를 입력하세요."} type="text" name={"dept"} onChange={e=>updateForm("dept",e.target.value)}/>
                </div>
            </div>
            <div style={{display:"flex",columnGap:10}}>
                <div style={{flex:1}}>
                    <label htmlFor="company">담당자명</label>
                    <input placeholder={"담당자 이름을 입력하세요."} type="text" name={"charge"} onChange={e=>updateForm("charge",e.target.value)}/>
                </div>
                <div style={{flex:1}}>
                    <label htmlFor="company">연락처</label>
                    <NumericFormat placeholder={"담당자의 연락처를 입력하세요.(-없이 입력)"}  value={form.point} onChange={e=>updateForm("phone",e.target.value)} allowLeadingZeros allowNegative={false} />
                </div>
            </div>
            <label htmlFor="company_no">사업자등록증(고유번호증)</label>
            <div className={"upload"}>
                <input value={form.business?form.business.name:""} placeholder={"사업자등록증을 업로드하세요."} type="text" name={"company_no"} readOnly onClick={()=>businessUploadRef.current.click()}/>
                <Upload ref={businessUploadRef} name={"사업자등록증 업로드"} onUpload={file=>updateForm("business",file)} />
            </div>
            <label style={{marginTop:30}} htmlFor="account">통장사본</label>
            <div className={"upload"}>
                <input value={form.account?form.account.name:""} placeholder={"후원금을 환급받을 계좌의 통장사본을 업로드하세요."} type="text" name={"account"} readOnly onClick={()=>accountUploadRef.current.click()}/>
                <Upload ref={accountUploadRef} name={"통장사본 업로드"}  onUpload={file=>updateForm("account",file)}/>
            </div>
            <button disabled={loading} className={"submit"} onClick={join}>회원가입</button>
        </AuthLayout>
    )
}
export default Join
