import style from "./styles/auth.module.scss"
import background from "./assets/auth-back.jpeg"
const Layout = ({children})=>{
    return (
        <section className={style.auth}>
            <div style={{backgroundImage:"url("+background+")"}}>

            </div>
            <div>
                {children}
            </div>
        </section>
    )
}
export default Layout
