import style from "../styles/part.module.scss"
const SearchSelect = ({items,onChange,value = []})=>{
    const colors = {
        "신청완료":"#0C4DA1",
        "담당자확인":"#666666",
        "적립완료":"#34B44A",
        "신청반려":"#F5891D",
        "입금완료":"#34B44A",
        "취소":"#F5891D"
    }
    const handlerClick = (item)=>{
        if((value.indexOf(item)!==-1)){
            onChange(value.filter(v=>v!==item))
        }else{
            onChange([
                ...value,
                item
            ])
        }
    }
    return (
        <div className={style.select}>
            {
                items.map(item=>{
                    return (
                        <span style={{color:colors[item]}} onClick={()=>handlerClick(item)} className={(value.indexOf(item)!==-1)?"active":null} key={item}>
                            {item}
                        </span>
                    )
                })
            }
        </div>
    )
}
export default SearchSelect
