import style from "../styles/part.module.scss"
const SearchInput = ({onChange, onFocusOut, ...args})=>{
    return (
        <div className={style.search}>
            <input
                   type="text"
                   placeholder={"검색어를 입력하세요."}
                   onChange={e=>onChange(e.target.value)}
                   onBlur={onFocusOut}
                   onKeyDown={e=>(e.key==="Enter")?onFocusOut():null}
            />
        </div>
    )
}
export default SearchInput
