import style from '../styles/part.module.scss'
const Modal = ({children,onRegister, onClose,onUpdate})=>{
    return (
        <div className={style.modal}>
            <div>
                <div>
                    {children}
                </div>
                <div>
                    <button onClick={onClose} className={"close"}>
                        {onRegister?"취소하기":"닫기"}
                    </button>
                    {
                        onRegister?
                            <button onClick={onRegister} className={"register"}>신청하기</button>
                            :
                            null
                    }
                    {
                        onUpdate?
                            <button onClick={onUpdate} className={"register"}>재신청하기</button>
                            :null
                    }
                </div>
            </div>
        </div>
    )
}
export default Modal
