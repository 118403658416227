import Table from "../parts/Table";
import style from "../styles/main.module.scss";
import Modal from "../parts/Modal";
import {useContext, useEffect, useState} from "react";
import ApiContext from "../ApiContext";
import SearchInput from "../parts/SearchInput";
import SearchSelect from "../parts/SearchSelect";
import {NumericFormat} from "react-number-format";
import {Link} from "react-router-dom";

const Page = ()=>{
    const pb = useContext(ApiContext)
    const defaultForm = {
        user:pb.authStore.model.id,
        status:"신청완료",
        dept:pb.authStore.model.dept,
        buyer:pb.authStore.model.charge
    }
    const [create,setCreate]=useState(false)
    const [keyword,setKeyword]=useState("")
    const [filter,setFilter]=useState([])
    const [form,setForm]=useState(defaultForm)
    const [detail,select]=useState(null)
    const [info,setInfo]=useState({
        items:[],
        page:1,
        perPage:8,
        totalItems:0,
        totalPages:1
    })
    const [total,setTotal]=useState({
        count:0,
        sum:0
    })
    useEffect(()=>{
        console.log(form)
    },[form])
    useEffect(()=>{
        load()
    },[])
    useEffect(()=>{
        load()
    },[filter])
    const load = (page =1)=>{
        let filterString = `user="${pb.authStore.model.id}"`
        if(filter.length){
            let statusFilter = filter.map(item=>{
                return `status = "${item}"`
            })
            filterString+=`&& (${statusFilter.join("||")})`
        }
        if(keyword){
            let searchColumn = ["item","buyer","dept"]
            let searchFilter = searchColumn.map(item=>{
                return `${item} ~ "%${keyword}%"`
            })
            filterString+=`&& (${searchFilter.join("||")})`
        }
        pb.collection("papers").getList(page,8,{
            sort:"-created",
            filter:filterString
        }).then(res=>{
            setInfo(res)
        })
        pb.collection("user_stats").getOne(pb.authStore.model.id).then(res=>{
            setTotal({
                count: res.pageTotal,
                sum:Number(res.deposit)
            })
        })
    }
    const register = ()=>{
        console.log(form)
        if(form.item && form.dept && form.buyer && form.purchased && form.amount && form.point){
            pb.collection("papers").create(form).then(()=>{
                alert("구매등록이 완료되었습니다.")
                setCreate(false)
                load()
                setForm(defaultForm)
            })
        }else{
            alert("항목을 모두 채워주세요.")
        }
    }
    const updateForm=(key,value)=>{
        let obj = {}
        obj[key]=value
        if(key==="amount"){
            obj["point"]=getPoint(value)
        }
        setForm({
            ...form,
            ...obj
        })
    }
    const pad = function (d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    const parser = (record,k)=>{
        return [
            pad(k+1),
            record.created.substring(0,10),
            record.item,
            record.dept,
            record.buyer,
            record.amount.toLocaleString()+"원",
            record.purchased.substring(0,10),
            record.point.toLocaleString()+"원",
            <Status status={record.status} />
        ]
    }
    const Status = ({status})=>{
        const colors = {
            "신청완료":"#0C4DA1",
            "담당자확인":"#666666",
            "적립완료":"#34B44A",
            "신청반려":"#F5891D"
        }
        return (
            <span style={{color:colors[status]}}>{status}</span>
        )
    }
    const getPoint=(amount)=>{
        return Math.round(Number(amount)/11*10*0.03)
    }
    const resubmit = ()=>{
        let {item,purchased,amount}=detail
        setForm({
            ...defaultForm,
            item,
            purchased:purchased.substring(0,10),
            amount,
            point:getPoint(amount)
        })
        setCreate(true)
        select(null)
    }
    const checkDate = ()=>{
        if(form.purchased){
            if(form.purchased>new Date().toISOString().substring(0,10)){
                alert("미래의 날짜입니다.")
                setForm({
                    ...form,
                    purchased:""
                })
            }
        }
    }
    return (
        <div className={"full"}>
            <div className={style.title}>
                <h1>구매현황</h1>
                <p>구매 내역을 등록하고 비용의 3%를 사회적가치로 적립하세요. </p>
            </div>
            <div className={style.cards}>
                <div>
                    <h5>총 구매내역</h5>
                    <b>{total.count.toLocaleString()}건</b>
                </div>
                <div>
                    <h5>총 적립금</h5>
                    <b>{total.sum.toLocaleString()}원</b>
                </div>
                <button onClick={()=>setCreate(true)}>
                    구매내역 등록하기
                </button>
            </div>
            <Table columns={["순번","작성일자","구매품목","담당부서명","담당(구매자)","금액","구매일자","적립금","처리현황"]}
                   classes={["no","date","main","","","number","date","main number","status"]}
                   widths={[26,90,null,100,100,120,90,90,70]}
                   records={info.items}
                   handler={parser}
                   tfoot={
                       <p className={style.info}>적립된 포인트는 환급받을 수 있습니다.  <Link to={"/social"}>후원금 청구하러 가기 >></Link></p>
                   }
                   thead={
                       <div className={style.thead}>
                           <SearchInput onChange={v=>setKeyword(v)} onFocusOut={load}  />
                           <SearchSelect items={["신청완료","담당자확인","적립완료","신청반려"]} value={filter} onChange={v=>setFilter(v)} />
                       </div>
                   }
                   info={info}
                   onPage={page=>load(page)}
                   onClick={record=>select(record)}
            />
            {
                create?
                    <Modal onClose={()=>setCreate(false)} onRegister={register}>
                        <h2>
                            구매내역 등록하기
                        </h2>
                        <p>
                            * 후원금 신청은 분기 내역을 합산하여 진행합니다.<br/>  1~3월분: 4월부터<br/>4~6월:7월부터<br/>7~9월:10월부터<br/>10~12월분: 익년 1월부터<br/><br/>* 신청 후 후원금 입금까지 약 15일 정도 처리시간이 소요됩니다.<br/>* 3% 후원금은 물품구매 시 부가세 금액을 제외한 물품금액으로만 산정되며, 회원기관과 내음공간의 상호 확인하여 최종 결정 됩니다.
                        </p>
                        <ul>
                            <li>
                                <b>
                                    구매품목
                                </b>
                                <input type="text" value={form.item}  onChange={e=>updateForm("item",e.target.value)}/>
                            </li>
                            <li>
                                <b>
                                    담당부서명
                                </b>
                                <input type="text" value={pb.authStore.model.dept} readOnly={true}/>
                            </li>
                            <li>
                                <b>
                                    담당(구매자)
                                </b>
                                <input type="text" value={pb.authStore.model.charge} readOnly={true} />
                            </li>
                            <li>
                                <b>
                                    구매일자
                                </b>
                                <input type="date" value={form.purchased} onChange={e=>updateForm("purchased",e.target.value)} max={new Date().toISOString().substring(0,10)} onBlur={checkDate}/>
                            </li>
                            <li>
                                <b>
                                    구매금액
                                </b>
                                <NumericFormat value={form.amount} onChange={e=>updateForm("amount",Number(e.target.value.replace(/,/g,"")))} allowLeadingZeros thousandSeparator={","}  />

                            </li>
                            <li>
                                <b>
                                    3% 포인트적립금
                                </b>
                                {
                                    form.amount?
                                        <p>{getPoint(form.amount).toLocaleString()}</p>
                                        :
                                        <p>*구매금액의 3%가 자동계산됩니다.</p>
                                }
                            </li>
                        </ul>
                    </Modal>
                    :null
            }
            {
                detail?
                    <Modal onClose={()=> {
                        select(null)
                        setForm(defaultForm)
                    }} onUpdate={detail.status==="신청반려"?resubmit:null}>
                        <h2>
                            구매내역
                        </h2>
                        <p>
                            * 후원금 신청은 분기 내역을 합산하여 진행합니다.<br/>  1~3월분: 4월부터<br/>4~6월:7월부터<br/>7~9월:10월부터<br/>10~12월분: 익년 1월부터<br/><br/>* 신청 후 후원금 입금까지 약 15일 정도 처리시간이 소요됩니다.<br/>* 3% 후원금은 물품구매 시 부가세 금액을 제외한 물품금액으로만 산정되며, 회원기관과 내음공간의 상호 확인하여 최종 결정 됩니다.
                        </p>
                        <ul>
                            <li>
                                <b>
                                    구매품목
                                </b>
                                <span>
                                    {detail.item}
                                </span>
                            </li>
                            <li>
                                <b>
                                    담당부서명
                                </b>
                                <span>
                                    {detail.dept}
                                </span>
                            </li>
                            <li>
                                <b>
                                    담당(구매자)
                                </b>
                                <span>
                                    {detail.buyer}
                                </span>
                            </li>
                            <li>
                                <b>
                                    구매일자
                                </b>
                                <span>
                                    {detail.purchased.substring(0,10)}
                                </span>
                            </li>
                            <li>
                                <b>
                                    구매금액
                                </b>
                                <span>
                                    {detail.amount.toLocaleString()}
                                </span>
                            </li>
                            <li>
                                <b>
                                    3% 포인트적립금
                                </b>
                                <span>
                                    {detail.point.toLocaleString()}
                                </span>
                            </li>
                            {
                                detail.status==="신청반려"?
                                    <li>
                                        <b style={{color:"red"}}>
                                            반려 사유
                                        </b>
                                        <span>
                                            {detail.memo}
                                        </span>
                                    </li>
                                    :null
                            }
                        </ul>
                    </Modal>:null
            }
        </div>
    )
}
export default Page
